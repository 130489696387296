.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg-orange {
  color: white;
  background-color: #ef8428;
}

.btn-primary {
  color: #fff;
  background-color: rgb(97, 195, 237);
  border-color: rgb(97, 195, 237);
}

.btn-small-margin {
  margin : 0.2em;
}

.card {
  margin-top: 1em;
  margin-bottom: 1em;
}

.close {
  border: none;
  background: transparent;
  font-size: 1em;
}

.col-sm {
  width: 7em;
}

.col-md {
  width: 11em;
}

.col-lg {
  width: 25em;
}

.full-spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
}

.img-partner {
  object-fit: cover;
  height: 15em;
  width: 15em;
}

.img-sale {
  object-fit: scale-down;
  height: 15em;
  width: 100%;
}

.sf-spinner {
  margin-left: 50%;
  margin-top: 10%;
}

.left-menu {
  position: fixed;
  z-index: 1;
  width: 13em;
  height: 100%;

  color: #fff;
  background-color: rgb(97, 195, 237);
  padding-inline-start: 0.2em;
  padding-block-start: 0.2em;
  padding-inline-end: 0.4em;
}

.left-menu ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  padding: 0;
}

.left-menu li {
  margin: 0;
  padding: 0.5em;
}
.left-menu li ul li {
  border-left: 1px solid rgb(63, 63, 63);
}

.left-menu a {
  color: rgb(63, 63, 63);
  font-variant-caps: small-caps;
  text-decoration: none;
}

.left-menu a:hover {
  color: #fff;
}

.login-box {
  max-width: 30em;
  margin-top: 2em;
  margin-bottom: 2em;
}

.menu-active {
  font-weight: bold;
}

.pva-body {
  margin-left: 13em;
}

.small-margin-right {
  margin-right: 0.2em;
}

.xl-check-input {
  width : 1.5em;
  height: 1.5em;
}

.xl-check-label {
  padding-left: 1em;
  padding-top: 0.25em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
